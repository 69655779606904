<script>
export default {
  name: 'FormatDate',
  methods: {
    formatDate(inputDate) {
      if (inputDate) {
        const date = new Date(inputDate);
        const options = {
          year: 'numeric', month: 'long', weekday: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit',
        };
        const formattedDate = date.toLocaleDateString('de-DE', options);
        return formattedDate;
      }
      return inputDate;
    },
  },
};
</script>
