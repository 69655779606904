<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-new-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
  </svg>
</template>

<script>
export default {
  name: 'chevronSVG',
};
</script>
