<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'startExamMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const startExamMutation = gql`
    mutation($examId: ID!, $userDataId: ID!) {
      startExam(examId: $examId, userDataId: $userDataId) {
        data {
          id
          attributes {
            Frage
            Antwort {
              id
              Antwort
            }
            Kommentar
            sumCorrect
          }
        }
      }
    }
    `;

    const context = reactive({
      headers: computed(() => store.state.auth.headers),
    });

    const { execute: startExam } = useMutation(startExamMutation, {
      context,
    });

    store.commit('setStrapiExecutionHandler', { item: 'startExam', result: startExam });
  },
};
</script>
